<template>
  <div class="paySuccess006">

  </div>
</template>

<script>
  export default {
    name: "paySuccess006",
    data() {
      return {}
    },
    components: {},
    beforeRouteEnter(to, from, next) {
      if(to.query.state = 'success'){
        next((vm) => {
          vm.toNext();
        });
      }

    },
    created() {

    },
    mounted() {

    },
    methods: {
      toNext() {
        this.$router.push({
          path:"/pay/paySuccess",
          query: {
            payType: 1,
            td: this.$route.query.td
          }
        });
        return;
        switch (this.$route.query.pd * 1) {
          case 1: {
            if(this.$route.query.pdd != null){
              this.$router.push({
                path: "/registerSoft/mailingInformation",
                query: Object.assign({}, this.$route.query)
              })
            }else{
              this.$router.push({
                path: "/register/uploadMaterial",
                query: Object.assign({}, this.$route.query)
              })
            }
          }
            break;
          case 2: {
            this.$router.push({
              path: "/register/last",
            })
          }
            break;
        }
      },
    },
    computed: {},
  }
</script>

<style scoped lang="less">
  .paySuccess006 {

  }
</style>
